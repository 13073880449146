import { Link } from "gatsby";
import { FiInfo } from "react-icons/fi";
import Img from "gatsby-image";
import Title from "../../../../src/components/Title";
import * as React from 'react';
export default {
  Link,
  FiInfo,
  Img,
  Title,
  React
};